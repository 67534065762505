import {useState} from 'react'
import ButtonNavigation from '../components/ButtonNavigation'
import {ButtonSubmit} from '../Form'
import {props} from './models'

export default function InternalNavigation({
    loading = false,
    pages,
    labels,
    buttonNext,
    buttonPrev,
    titleSubmit,
}: props) {
    const keys = Object.keys(pages)
    const [page, setPage] = useState(keys[0])

    function previousPage() {
        const index = keys.indexOf(page)
        if (index > 0) {
            setPage(keys[index - 1])
        }
    }
    function nextPage() {
        const index = keys.indexOf(page)
        if (index < keys.length - 1) {
            setPage(keys[index + 1])
        }
    }

    function inLastPage() {
        return keys.indexOf(page) === keys.length - 1
    }

    function inFirstPage() {
        return keys.indexOf(page) === 0
    }

    return (
        <div className='d-flex flex-column flex-md-row'>
            <menu className='m-0 p-0'>
                <ul className='p-0 me-md-6'>
                    {keys.map((key, index) => (
                        <ButtonNavigation
                            onClick={() => setPage(key)}
                            label={labels[index]}
                            active={page === key}
                            key={key}
                        />
                    ))}
                </ul>
            </menu>
            <main className='w-100'>
                {pages[page]}
                <div
                    className={`d-flex w-100 justify-content-${
                        buttonPrev ? 'between' : 'end'
                    } my-4`}
                >
                    {buttonPrev && (
                        <div className='d-flex justify-content-end my-4'>
                            {!inFirstPage() && (
                                <button
                                    type='button'
                                    className='btn btn-light-primary'
                                    onClick={() => previousPage()}
                                >
                                    <i className='fas fa-arrow-left' />
                                    Anterior
                                </button>
                            )}
                        </div>
                    )}
                    {buttonNext && (
                        <div className='d-flex justify-content-end my-4'>
                            {!inLastPage() ? (
                                <button
                                    type='button'
                                    className='btn btn-light-primary'
                                    onClick={() => nextPage()}
                                >
                                    <i className='fas fa-arrow-right' />
                                    Próxima
                                </button>
                            ) : (
                                <ButtonSubmit loading={loading} title={titleSubmit} />
                            )}
                        </div>
                    )}
                </div>
            </main>
        </div>
    )
}
